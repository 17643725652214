import { createApp } from 'vue'
import App from './App.vue'
import { createHead } from '@vueuse/head'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from './assets/js/store.js';

// 1. De
import router from './router'

import './registerServiceWorker'
const head = createHead()


createApp(App).use(head).use(store).use(router).mount('#app')

